window.jQuery = jQuery;
window.$ = jQuery;

export { createLocalize } from './L10n';

export { initialize as tagManager } from './TagManager';
export { ScrollTopCache } from './util';

import * as constants from './constants';
export const CONSTANTS = constants;

import dayjs_ from 'dayjs';
import 'dayjs/locale/de';
export const dayjs = dayjs_;

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);

import 'jquery-ui/ui/widgets/datepicker';
$.datepicker.regional['de'] = {
  closeText: 'Schließen',
  prevText: '&#x3C;Zurück',
  nextText: 'Vor&#x3E;',
  currentText: 'Heute',
  monthNames: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
  dayNames: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  weekHeader: 'KW',
  dateFormat: 'dd.mm.yy',
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: '',
};
$.datepicker.setDefaults($.datepicker.regional['de']);
