const sprintf = (format, ...args) => {
  let i = 0;
  return format.replace(/%s/g, () => args[i++]);
};

export const createLocalize = function (locales) {
  return function (key, ...args) {
    let lang = 'de';

    if (window.sfp.lang === '1') {
      lang = 'en';
    }

    let translation =
      locales[key] && (locales[key][lang] || locales[key]['de']);

    if (!translation) {
      const warning = `Translation for "${key}" missing`;
      console.error(warning);

      return warning;
    }

    return sprintf(translation, ...args);
  };
};
