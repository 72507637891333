// This is also defined in PHP Snowflake/template/Service/TagManagerService
const META_NAME_PREFIX = 'tagmanager-';

export const getNormalizedMetaData = function () {
  const metaEls = document.querySelectorAll('meta');

  return Array.prototype.reduce.call(
    metaEls,
    function (carry, metaEl) {
      let name = metaEl.getAttribute('name');

      if (!name || name.indexOf(META_NAME_PREFIX) < 0) return carry;
      name = name.slice(META_NAME_PREFIX.length);

      const content = metaEl.getAttribute('content');
      if (!content) return carry;

      carry[name] = content;

      return carry;
    },
    {}
  );
};

export const initialize = function () {
  window.dataLayer = [getNormalizedMetaData()];
};
